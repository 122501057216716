









































import {Component, Vue} from 'vue-property-decorator';
import {validEmail} from '@/utils/validate';
import {AppModule } from '@/store/modules/appState';
import {Input} from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import {IForgotForm} from '@/models/auth';

@Component({
  name: 'Forgot',
  components: {}
})

export default class extends Vue {
  private forgotForm: IForgotForm = {
    email: '',
  }
  private loading = false;

  mounted() {
    if (this.forgotForm.email === '') {
      (this.$refs.email as Input).focus()
    }
  }

  private validateEmail(rule: any, value: string, callback: Function) {
    if (validEmail(value)) {
      callback(new Error('Please enter the correct email'))
    } else {
      callback()
    }
  }

  private forgotRules = {
    email: [{validator: this.validateEmail, trigger: 'blur'}],
  }

  private handleForgot() {
    (this.$refs.forgotForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        await AppModule.Forgot(this.forgotForm);
        setTimeout(() => {
          this.loading = false
        }, 0.5 * 1000)
      } else {
        return false
      }
    })
  }

}
