






























































import { Component, Vue } from 'vue-property-decorator';
import { ILoginForm } from '@/models/auth';
import { validEmail } from '@/utils/validate';
import { AppModule } from '@/store/modules/appState';
import { Input } from 'element-ui';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'Login',
  components: {}
})
export default class extends Vue {
  private loginForm: ILoginForm = {
    email: '',
    password: ''
  };
  private loading = false;

  mounted() {
    if (this.loginForm.email === '') {
      (this.$refs.email as Input).focus();
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus();
    }
  }

  private validateEmail(rule: string, value: string, callback: Function) {
    if (validEmail(value)) {
      callback(new Error('Please enter the correct email'));
    } else {
      callback();
    }
  }

  private validatePassword(rule: string, value: string, callback: Function) {
    if (value.length < 6) {
      callback(new Error('The password can not be less than 6 characters'));
    } else {
      callback();
    }
  }

  private loginRules = {
    email: [{ validator: this.validateEmail, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  };

  private async handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        await AppModule.Login(this.loginForm).then(response => {
          if (response) {
            this.$router.push(
              response.data.isAdmin ? { path: '/admin' } : { path: '/user' }
            );
          }
        });
        /* setTimeout(() => {
          this.loading = false
        }, 0.5 * 1000) */
      } else {
        return false;
      }
    });
  }
}
