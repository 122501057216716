




















































































import { Component, Vue } from 'vue-property-decorator';
import { IRegisterForm } from '@/models/auth';
import { validEmail } from '@/utils/validate';
import { Input } from 'element-ui';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'Login',
  components: {}
})

export default class extends Vue {
  private registerForm: IRegisterForm = {
    email: '',
    password: '',
    userName: '',
    confirmPassword: ''
  }
  private loading = false;

  mounted() {
    if (this.registerForm.email === '') {
      (this.$refs.email as Input).focus()
    } else if (this.registerForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private validateEmail(rule: any, value: string, callback: Function) {
    if (validEmail(value)) {
      callback(new Error('Please enter the correct email'))
    } else {
      callback()
    }
  }

  private validatePassword(rule: any, value: string, callback: Function) {
    if (value.length < 6) {
      callback(new Error('The password can not be less than 6 characters'))
    } else {
      callback()
    }
  }

  private validateUsername(rule: any, value: string, callback: Function) {
    if (value.length < 6) {
      callback(new Error('The username can not be less than 6 characters'))
    } else {
      callback()
    }
  }

  private validateMatchPassword(rule: string, value: string, callback: Function) {
    if (value !== this.registerForm.password) {
      callback(new Error('Those passwords didn\'t match'))
    } else {
      callback()
    }
  }

  private registerRules = {
    email: [{validator: this.validateEmail, trigger: 'blur'}],
    password: [{validator: this.validatePassword, trigger: 'blur'}],
    confirmPassword: [{validator: this.validateMatchPassword, trigger: 'blur'}],
    userName: [{validator: this.validateUsername, trigger: 'blur'}]
  }

  private handleRegister() {
    (this.$refs.registerForm as ElForm).validate(async (valid: boolean) => {

      if (valid) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false
        }, 0.5 * 1000)
      } else {
        return false
      }
    })
  }

}
