

























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/appState';
import { Input } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { IResetForm } from '@/models/auth';

@Component({
  name: 'Reset',
  components: {}
})
export default class extends Vue {
  private resetForm: IResetForm = {
    password: '',
    confirmPassword: ''
  };
  email = '';
  token = '';
  private loading = false;
  private isValid = false;

  mounted() {
    if (this.$route.query.token && this.$route.query.email) {
      this.token = this.$route.query.token.toString();
      this.email = this.$route.query.email.toString();
    }
    if (this.resetForm.password === '') {
      (this.$refs.password as Input).focus();
    } else {
      (this.$refs.confirmPassword as Input).focus();
    }
  }

  private resetRules = {
    password: [
      {
        validator: (rule: string, value: string, callback: Function) => {
          console.log('Value: ', value);
          value.match(
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*().,/?:;])([a-zA-Z0-9!@#$%^&*().,/?:;]+){6,}$/
          )
            ? callback()
            : callback(
                new Error(
                  'Password must be at least 6 characters long, must contain at least one uppercase, one lowercase letter, a number, and a symbol "!@#$%^&*().,/?:;".'
                )
              );
        },
        trigger: 'blur'
      }
    ],
    confirmPassword: [
      {
        validator: (rule: string, value: string, callback: Function) => {
          value !== this.resetForm.password
            ? // eslint-disable-next-line
              callback(new Error("Those passwords didn't match"))
            : callback();
        },
        trigger: 'blur'
      }
    ]
  };

  private async handleReset() {
    if (this.isValid) {
      this.loading = true;

      await AppModule.Reset({
        password: this.resetForm.password,
        hash: this.token,
        email: this.email
      });

      this.loading = false;
    }
  }

  @Watch('resetForm', { deep: true })
  private validateForm() {
    (this.$refs.resetForm as ElForm).validate(
      (valid: boolean) => (this.isValid = valid)
    );
  }
}
